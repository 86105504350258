<template>
	<v-container fluid class="pa-0">
		<v-toolbar flat dark :style="stickyTop" extension-height="4">
			<v-toolbar-title>{{ $t("Verkoop dagboek") }} </v-toolbar-title>
			<v-spacer />
			<v-autocomplete style="width: auto; max-width: 225px" class="text-overline kmp" v-model="fiscalPeriod"
				:items="sessionStore.fiscalPeriods" placeholder="filter op periode" hide-details solo flat dense outlined
				clearable append-icon="mdi-filter-outline" @change="refreshsalesJournal"></v-autocomplete>
			<template #extension v-if="loading">
				<v-progress-linear bottom indeterminate color="white"></v-progress-linear>
			</template>
		</v-toolbar>
		<v-container>
			<v-row v-for="invoice of salesJournal" :key="invoice.stuknr">
				<v-col cols="12">
					<template>
						<sales-journal-card :invoice="invoice" />
					</template>
				</v-col>
			</v-row>
			<v-row>
				<v-spacer />
				<v-btn text outlined class="my-3" v-if="this.rowIdent" color="primary" :loading="loading"
					@click="fetchSalesJournal()">
					toon meer ...
				</v-btn>
				<v-spacer />
			</v-row>
		</v-container>
	</v-container>
</template>

<script>
import caas from "@/services/caas";
import SalesJournalCard from "@/components/SalesJournalCard";
import { useSessionStore } from "@/store/session";
export default {
	components: { SalesJournalCard },
	name: "SalesJournal",
	computed: {
		stickyTop() {
			return {
				position: "sticky",
				top: this.$vuetify.application.top + "px",
				zIndex: 5,
			};
		},
	},
	data() {
		return {
			fiscalPeriod: null,
			loading: false,
			salesJournal: [],
			rowNum: 0,
			rowIdent: "",
			rowsToBatch: 25,
			sessionStore: useSessionStore()
		};
	},
	methods: {
		async fetchSalesJournal() {
			this.loading = true;
			let params = {
				year: Number(this.sessionStore.fiscalYear),
				period: Number(this.fiscalPeriod) || 0,
				rowNum: this.rowNum,
				rowIdent: this.rowIdent,
				rowsToBatch: this.rowsToBatch,
			};
			const response = await caas.rpc("getSalesJournal", params);
			if (response.data) {
				this.salesJournal.push(
					...response.data.data.salesJournal.invoice
				);

				this.rowNum = response.data.rowNum;
				this.rowIdent = response.data.rowIdent;
			} else if (response.data.error) {
				console.log(response.error);
			}
			this.loading = false;
		},
		refreshsalesJournal() {
			this.salesJournal = [];
			this.rowNum = 0;
			this.rowIdent = "";
			this.fetchSalesJournal();
		},
	},
	mounted() {
		this.fetchSalesJournal();
	},
};
</script>

<style>
</style>