
<template>
	<v-card min-width="600" max-width="960" class="mx-auto">
		<v-card-title class="text-subtitle-2">
			<v-row align="center">
				<v-col cols="1">
					{{ invoice.stuknr }}
				</v-col>
				<v-col>
					{{ $d(new Date(invoice.datum), 'short') }}
				</v-col>
				<v-col cols="4">
					{{ invoice.klantNaam }}
				</v-col>
				<v-spacer />
				<v-col align="right">
					{{ $n(invoice.totaalbedrag, 'ccy') + ' ' + invoice.muntcode }}
				</v-col>
				<v-spacer />
				<v-col align="right">
					<v-btn text color="info" :loading="loading" @click.stop="toggleShowPdf">
						<v-icon left>mdi-file-document-outline</v-icon>
						<span>pdf</span>
					</v-btn>
				</v-col>
			</v-row>
		</v-card-title>
		<v-divider />
		<v-card-text class="grey lighten-5">
			<v-row v-if="showPdf">
				<v-col cols="12">
					<v-sheet outlined max-height="450" style="overflow-y:auto">
						<pdf :src="src" />
					</v-sheet>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" lg="8">
					<v-sheet outlined>
						<v-data-table disable-sort disable-pagination hide-default-footer :items="invoice.booking"
							:headers="bookingHeaders" dense>
							<template v-slot:[`item.debet`]="{ item }">
								<span v-if="item.debet">{{
									$n(item.debet, "ccy")
								}}</span>
							</template>
							<template v-slot:[`item.credit`]="{ item }">
								<span v-if="item.credit">{{
									$n(item.credit, "ccy")
								}}</span>
							</template>
						</v-data-table>
					</v-sheet>
				</v-col>
				<v-col cols="12" lg="4">
					<v-sheet outlined>
						<v-data-table disable-sort disable-pagination hide-default-footer :items="invoice.vatBooking"
							:headers="vatBookingHeaders" dense>
							<template v-slot:[`item.bedrag`]="{ item }">
								<span>{{ $n(item.bedrag, "ccy") }}</span>
							</template>
						</v-data-table>
					</v-sheet>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
import pdf from "vue-pdf";
import caas from "@/services/caas";
import { useSessionStore } from "@/store/session";
export default {
	name: "SalesJournalCard",
	components: {
		pdf,
	},
	props: {
		invoice: Object,
	},

	data() {
		return {
			loading: false,
			bookingHeaders: [
				{ text: "Periode", value: "periode" },
				{ text: "Reknr", value: "gnr" },
				{
					text: "Omschrijving",
					value: "omschrijving",
					align: "start",
					width: "50%",
				},
				{ text: "Debet", value: "debet", align: "end" },
				{ text: "Credit", value: "credit", align: "end" },
			],
			vatBookingHeaders: [
				{ text: "Rooster", value: "roosternr" },
				{ text: "Bedrag", value: "bedrag", align: "end" },
			],
			src: null,
			showPdf: false,
			sessionStore: useSessionStore()
		};
	},
	computed: {},
	methods: {
		getPdf() {
			this.loading = true;
			let params = {
				fiscalYear: Number(this.sessionStore.fiscalYear),
				invoiceId: Number(this.invoice.stuknr),
			};
			caas.rpc("getInvoiceB64", params).then((response) => {
				if (response.data) {
					const binaryString = window.atob(response.data.invoiceB64)
					const len = binaryString.length;
					const bytes = new Uint8Array(len);
					for (let i = 0; i < len; ++i) {
						bytes[i] = binaryString.charCodeAt(i);
					}
					this.src = bytes
					this.showPdf = true;
				} else {
					if (response.data.error) {
						this.sessionStore.showError(response.data.error);
						this.showPdf = false;
					}
				}
				this.loading = false;
			});
		},

		toggleShowPdf() {
			this.showPdf = !this.showPdf;
			if (this.showPdf) {

				if (!this.src) this.getPdf();

			}
		},
	},
};
</script>

